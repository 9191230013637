"use client"

import { classNames } from "@local/utils/src/classNames"
import { Button } from "@local/ui/src/Button"
import styles from "./styles.module.css"
import { ShoppingCartIcon } from "./ShoppingCartIcon"
import Cookies from "js-cookie"
import { usePathname, useSearchParams } from "next/navigation"
import CallToAction, { getInitialCtaString } from "../CallToAction"
import { Suspense } from "react"
import { Payload } from "@local/payload-client/src/types"
import { rebuildUrlParams } from "@local/utils"
import HydrationContainer from "../HydrationContainer"

interface Props {
  bgColor: Payload.Anchor["buttonBgColor"]
  callToAction?: string | null
  isMirror?: boolean
  noDiscount?: boolean
  noIcon?: boolean
  skipView?: boolean
  slug?: string | null
  textColor: Payload.Anchor["buttonTextColor"]
  url?: string | null
}

interface BtnBuyProps extends Props {
  full?: boolean
}

export const BtnBuy = ({
  bgColor,
  callToAction,
  full,
  isMirror,
  noDiscount,
  slug,
  textColor,
  url,
}: BtnBuyProps) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const isB2BPage = pathname?.includes("business")
  const isContactPage = pathname?.includes("contact")
  const multiUrlPrefix = !!slug ? `/lp/${slug}` : ""

  if (isContactPage) {
    return null
  }

  const checkoutUrl = isB2BPage
    ? "https://ask.baerskinhoodie.com/business"
    : isMirror
      ? noDiscount
        ? `https://baerskintactical.com${rebuildUrlParams(searchParams, `/lp/${slug}/checkout/x/`)}`
        : `https://baerskintactical.com${rebuildUrlParams(searchParams, `/lp/${slug}/checkout/`)}`
      : url
        ? url
        : noDiscount
          ? `${multiUrlPrefix}/x/checkout/`
          : `${multiUrlPrefix}/checkout/`

  return (
    <div className={classNames(full ? styles.BtnBuy__Full : "", styles.BtnBuy)}>
      <div
        className={classNames(
          full ? styles.BtnBuy__Full : "",
          full ? styles.BtnBuy__wrapper_full : styles.BtnBuy__wrapper,
          full ? "transition-colors duration-500 hover:opacity:75" : ""
        )}
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      >
        <Button
          className={classNames(
            full ? styles.BtnBuy__Full__Link : styles.BtnBuy__link,
            "transition-colors duration-500 hover:opacity:75"
          )}
          full={full}
        >
          <a
            href={checkoutUrl}
            className="hover:no-underline hover:cursor-pointer min-w-[220px] flex justify-center items-center gap-2"
            onClick={() => Cookies.remove("show-upsell-modal")}
          >
            <ShoppingCartIcon
              className={styles.BtnBuy__Icon}
              aria-hidden="true"
            />
            <span data-cy="buy-button" className="w-full">
              {isB2BPage ? (
                "Order BÆRSkin in bulk now"
              ) : (
                <>
                  <HydrationContainer fallback={<>{getInitialCtaString()}</>}>
                    <CallToAction
                      input={!noDiscount ? callToAction : null}
                      action={url ? "View price" : "Select Size Now"}
                      // truncate
                    />
                  </HydrationContainer>
                </>
              )}
            </span>
          </a>
        </Button>
      </div>
      {!full && (
        <div className="block p-4 md:hidden">
          {
            /*(priceViewed || url) && !noIcon ? (*/
            <a
              href={checkoutUrl}
              // target={url ? "_blank" : "_self"}
              className="hover:no-underline hover:cursor-pointer"
              onClick={() => Cookies.remove("show-upsell-modal")}
            >
              <ShoppingCartIcon
                className="block w-8 h-8 md:mr-2 md:h-5 md:w-5"
                aria-hidden="true"
              />
            </a>
          }
        </div>
      )}
    </div>
  )
}

export const BottomBtnBuy = ({
  bgColor,
  callToAction,
  isMirror,
  noDiscount,
  slug,
  textColor,
  url,
}: Props) => {
  return (
    <div className={styles.BottomBtnBuy}>
      <Suspense>
        <BtnBuy
          bgColor={bgColor}
          callToAction={callToAction}
          full
          isMirror={isMirror}
          noDiscount={noDiscount}
          slug={slug}
          textColor={textColor}
          url={url}
        />
      </Suspense>
    </div>
  )
}

BottomBtnBuy.displayName = "BottonBtnBuy"

// export default memo(BottomBtnBuy)
export default BottomBtnBuy
