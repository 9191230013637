"use client"
import { useScroll } from "@local/utils/src/useScroll"
import { Fragment, useEffect } from "react"
import Link from "next/link"
import { AnchorScroll } from "../AnchorScroll"
import { usePageLayout } from "../../usePageLayout"

type NavLink = {
  name: string
  href: string
  component?: never
}
type NavAnchor = { name: string; component: "about"; href?: never }
type NavItem = NavLink | NavAnchor

const navigation: NavItem[] = [
  { name: "FAQ", href: "faq" },
  { name: "Reviews", href: "reviews" },
  { name: "About us", component: "about" },
]

export const MenuDesktop = ({
  notHome,
  slug,
  isListicle,
}: {
  notHome?: boolean
  slug?: string | null
  isListicle?: boolean | null
}) => {
  const { togglePage } = usePageLayout()
  // const { IS_HAM } = GET_ANY_CHANNEL_ID(storeId)

  return (
    <div className="hidden	whitespace-nowrap lg:ml-6 lg:flex lg:items-center lg:space-x-4">
      {!isListicle ? (
        <Link
          href={slug ? `/lp/${slug}/#innovation` : `/#innovation`}
          className="rounded-md px-3 py-2 font-medium"
        >
          Our Innovation
        </Link>
      ) : (
        ""
      )}
      {navigation.map((item, i) => (
        <Fragment key={`MenuDesktop-${i}`}>
          {notHome ? (
            <Link
              key={`desktop-${item.name}-${i}`}
              href={slug ? `/lp/${slug}/#${item.href}` : `/#${item.href}`}
              className="rounded-md px-3 py-2 font-medium"
            >
              {item.name}
            </Link>
          ) : item.href ? (
            <AnchorScroll
              key={`desktop-${item.name}-${i}`}
              to={item.href}
              className="rounded-md px-3 py-2 font-medium"
            >
              {item.name}
            </AnchorScroll>
          ) : (
            <button
              key="about us desktop"
              onClick={() => {
                togglePage("about")
              }}
              className="rounded-md px-3 py-2 font-medium hover:underline"
            >
              {"About us"}
            </button>
          )}
        </Fragment>
      ))}
      {/* {IS_HAM && <Link href="/blog">Blog</Link>}
      {IS_HAM && <Link href="/drmauricio">Dr Mauricio</Link>} */}
    </div>
  )
}

export const MenuMobile = ({
  toggle,
  notHome,
  slug,
  isListicle,
}: {
  toggle: () => void
  notHome?: boolean
  slug?: string | null
  isListicle?: boolean | null
}) => {
  const { togglePage } = usePageLayout()
  const { scrollDirection } = useScroll()

  useEffect(() => {
    if (scrollDirection) {
      toggle()
    }
  }, [scrollDirection, toggle])

  return (
    <div className="lg:hidden">
      <div className="container mx-auto space-y-1 pb-3 pt-2">
        {!isListicle ? (
          <Link
            href={slug ? `/lp/${slug}/#innovation` : `/#innovation`}
            className="block w-full rounded-md px-4 py-2 text-left font-medium rtl:text-right"
          >
            Our Innovation
          </Link>
        ) : (
          ""
        )}
        {navigation.map((item, i) => (
          <Fragment key={`MenuMobile-${i}`}>
            {notHome ? (
              <Link
                key={`mobile-${item.name}-${i}`}
                href={slug ? `/lp/${slug}/#${item.href}` : `/#${item.href}`}
                onClick={toggle}
                className="block w-full rounded-md px-4 py-2 text-left font-medium rtl:text-right"
              >
                {item.name}
              </Link>
            ) : item.href ? (
              <AnchorScroll
                key={`mobile-${item.name}-${i}`}
                to={item.href}
                onClick={toggle}
                className="block w-full rounded-md px-4 py-2 text-left font-medium rtl:text-right"
              >
                {item.name}
              </AnchorScroll>
            ) : (
              <button
                key="about us mobile"
                onClick={() => {
                  toggle()
                  togglePage("about")
                }}
                data-cy="about"
                className="block w-full rounded-md px-4 py-2 text-left font-medium rtl:text-right"
              >
                About us
              </button>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
