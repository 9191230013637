/**
 * This rebuilds the url from searchParams, for cases where we need to redirect keeping something that exists
 * @param searchParams the params that already exist in the page
 * @param page the path of the page you want to send the user, keep nothing if you want to default to root
 * @param inject parameter to inject in case we have something to add to what's being rebuilt
 */
export const rebuildUrlParams = (
  searchParams: URLSearchParams,
  page: string = "/",
  inject?: [string, string]
) => {
  const parameters: Array<{ key: string; value: string }> = []

  searchParams?.forEach((value, key) => {
    if (key === "to") {
      page = value
    } else {
      parameters.push({ key, value })
    }
  })

  if (inject) {
    parameters.push({
      key: inject[0],
      value: inject[1],
    })
  }

  let rebuiltParams = ""

  for (let i = 0; i < parameters.length; i++) {
    const char = i === 0 ? "?" : "&"
    const item = parameters[i]
    rebuiltParams = rebuiltParams + char + item.key + "=" + item.value
  }

  const destination = `${page}${rebuiltParams}`

  return destination
}
