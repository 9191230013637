"use client"
import { useI10n } from "@local/i10n"
import { Payload } from "@local/payload-client/src/types"
import { isBrowser } from "@local/utils/src/isBrowser"
import { startTransition, useEffect, useState } from "react"

interface Props {
  action?: string
  input?: string | null
  truncate?: boolean
}

// fallback in english, as we don't have access to the locale in the server
export const getInitialCtaString = (input?: string | null, action?: string) =>
  input
    ?.replace("%%DATE%%", "Today")
    ?.replace("%%ACTION%%", action || "Buy now") || "Buy now"

export const CallToAction = ({ action, input, truncate }: Props) => {
  const { locale } = useI10n()
  //  const ctaText = callToAction && callToAction !== "" ? callToAction :  "Buy now"

  const [callToAction, setCallToAction] = useState<string>(
    getInitialCtaString(input, action || "Select size")
  )

  const parseCallToAction = () => {
    if (isBrowser() && input && input.indexOf("%%DATE") > -1) {
      const date = new Date()?.toLocaleString(
        locale.replace("_", "-") ?? "en-US",
        {
          day: "numeric",
          month: "short",
        }
      )

      const data = input
        .replace("%%DATE%%", date)
        .replace("%%ACTION%%", action || "Select size")

      return data
    } else {
      const text = input ?? "Buy now"
      return text
        ?.replace("%%DATE%%", "Today")
        ?.replace("%%ACTION%%", action || "Select size" || "Buy now")
    }
  }

  useEffect(() => {
    startTransition(() => {
      setCallToAction(parseCallToAction()!)
    })
  })

  return (
    <span
      className={
        truncate
          ? "block w-full overflow-hidden whitespace-nowrap md:whitespace-normal text-ellipsis"
          : "block leading-5"
      }
    >
      {callToAction}
    </span>
  )
}

export default CallToAction
