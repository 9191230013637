"use client"
import Image from "next/image"
import { TopBar } from "./TopBar"
import { Fragment, useEffect, useState } from "react"

interface Props {
  color?: string
  country?: string
  showDiscount?: boolean
  topDiscount?: number | null
}

export const BannerShipping = ({
  country,
  color,
  showDiscount,
  topDiscount,
}: Props) => {
  const [topBarContent, setTopBarContent] = useState(<Fragment />)

  const date = new Date()?.toLocaleString("en-US", {
    day: "numeric",
    month: "long",
  })

  useEffect(() => {
    setTopBarContent(
      <span className="flex flex-row">
        {date} - Free{" "}
        <span className="block mx-1">
          {country && (
            <Image
              alt={`${country} flag`}
              className="block w-5 h-5 rounded-full"
              src={`https://flagcdn.com/48x36/${country.toLowerCase()}.png`}
              width={24}
              height={18}
            />
          )}
        </span>{" "}
        shipping {showDiscount && ` – Get -${topDiscount ?? 60}% off today`}
      </span>
    )
  }, [country, date, showDiscount, topDiscount])

  return (
    <TopBar>
      <div
        className="flex flex-row gap-x-0.5"
        style={{
          color,
        }}
      >
        {topBarContent}
      </div>
    </TopBar>
  )
}
