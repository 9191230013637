import { isBrowser } from "@local/utils/src/isBrowser"
import type { CSSProperties, ReactNode } from "react"
import { Scrollchor } from "react-scrollchor"

interface Props {
  children: ReactNode
  className?: string
  to: string
  onClick?: (
    focusableElement?:
      | HTMLElement
      | React.MutableRefObject<HTMLElement | null>
      | undefined
  ) => void
  style?: CSSProperties
  gotoCenter?: boolean
}

export const AnchorScroll = ({
  children,
  className,
  style,
  to,
  onClick,
  gotoCenter,
}: Props) => {
  const shouldOffset = isBrowser() && gotoCenter && document.getElementById(to)
  const element = shouldOffset && document.getElementById(to)

  return (
    <Scrollchor
      animate={{
        offset:
          shouldOffset && element
            ? (window.innerHeight / 2 - element.offsetHeight) * -4
            : -400,
        duration: 400,
      }}
      beforeAnimate={onClick ? () => onClick() : undefined}
      className={className}
      style={style}
      to={to}
    >
      {children}
    </Scrollchor>
  )
}

export default AnchorScroll
